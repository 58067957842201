import axios from 'axios';

export const authService = axios.create({
  baseURL: process.env.REACT_APP_AUTHENTICATION_SERVICE_BASEURL,
  headers: {
    'Content-type': 'application/json',
  },
});

export enum RegisterChannel {
  EMAIL = 'email',
}

export enum Region {
  ES = 'ES',
}

export enum OAuthProvider {
  GOOGLE = 'google',
}

export enum AuthorizeAction {
  LOGIN = 'login',
  REGISTER = 'register'
}

export interface LinkBasedRegisterRequest {
  identifier: string;
  displayName: string;
  channel: RegisterChannel;
  region: Region;
  referrer?: string | null;
}

export interface LinkBasedRegisterResponse {
  // TODO: add more statuses
  outcome: 'success';
  challenge: string;
  identifier: string;
  displayName: string;
}

export interface OAuthGetAuthUrlRequest {
  provider: OAuthProvider;
  redirectUrl: string;
  region: Region;
  referrer?: string | null;
}

export interface OAuthGetAuthUrlResponse {
  url: string;
}

export interface OauthAutorizeRequest {
  code: string;
  state: string;
}

export interface LinkBasedAuthorizeRequest {
  identifier: string;
  challenge: string;
}

export interface LinkBasedLoginRequest {
  identifier: string;
  channel: RegisterChannel;
  region: Region;
}

export interface LinkBasedLoginResponse {
  outcome: 'success';
  challenge: string;
  identifier: string;
}

export interface AuthorizeResponse {
  accessToken: string;
  refreshToken: string;
  user: {
    displayName: string | null;
    email: string;
    firstName: string | null;
    id: string;
    lastName: string | null;
    region: Region | null;
    phone: string | null;
    roles: string[];
    referralCode: string;
  };
}

export interface TokenRefreshResponse {
  accessToken: string;
  refreshToken: string;
}
