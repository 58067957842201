import { LocalStorageKeys } from '@common/constants';
import { FlowType } from '@common/services/application';

import { ApiError } from './errors';
import { isUUID } from './uuid';

export const ALLOWED_FLOW_TYPES = [FlowType.APPLICATION, FlowType.DRAWDOWN];

export function isArrayOfStringErrors(arr: any[]): arr is string[] {
  return Array.isArray(arr) && arr.every((item) => typeof item === 'string');
}

export function getFlowId() {
  const { REACT_APP_APPLICATION_FLOW_ID, REACT_APP_DRAWDOWN_FLOW_ID } = process.env;

  const possibleFlowTypeFromLocation = window.location.pathname.split('/')?.[2]; // e.g. ['', et-ee, application, ...rest]
  if (isUUID(possibleFlowTypeFromLocation)) {
    return possibleFlowTypeFromLocation;
  }
  const storedFlowType = sessionStorage.getItem(LocalStorageKeys.FLOW_ID_OR_TYPE) as FlowType;

  const flowTypeToCheck = storedFlowType ?? possibleFlowTypeFromLocation ?? null;

  const isAllowedFlowType =
    flowTypeToCheck && ALLOWED_FLOW_TYPES.indexOf(flowTypeToCheck as FlowType) !== -1;

  if (!isAllowedFlowType) {
    throw new ApiError('Undefined flow type');
  }

  switch (flowTypeToCheck) {
    case FlowType.APPLICATION:
      return REACT_APP_APPLICATION_FLOW_ID;
    case FlowType.DRAWDOWN:
      return REACT_APP_DRAWDOWN_FLOW_ID;
    default:
      return REACT_APP_APPLICATION_FLOW_ID;
  }
}
