import { LocalStorageKeys } from '@common/constants';
import { getAccessToken, getRefreshToken, getUserData } from '@common/utils';
import axios, { AxiosRequestConfig } from 'axios';

import { AuthorizeResponse, TokenRefreshResponse } from './authentication';

export const refreshToken = async () => {
  const { REACT_APP_AUTHENTICATION_SERVICE_BASEURL } = process.env;
  const refreshToken = getRefreshToken();
  const userData = getUserData();

  if (!refreshToken) {
    return;
  }
  const response = await axios.post<TokenRefreshResponse>(
    `${REACT_APP_AUTHENTICATION_SERVICE_BASEURL}/auth/refresh`,
    { token: refreshToken }
  );
  sessionStorage.setItem(
    LocalStorageKeys.USER,
    JSON.stringify({
      accessToken: response.data?.accessToken,
      refreshToken: response?.data?.refreshToken,
      user: userData?.user,
    } as AuthorizeResponse)
  );

  return response.data.accessToken;
};

export const initProtectedHttpService = ({
  baseURL,
  params = {},
}: {
  baseURL: string;
  params?: AxiosRequestConfig;
}) => {
  const accessToken = getAccessToken();

  const service = axios.create({
    baseURL,
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      ...params.headers,
    },
    ...params,
  });

  service.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      // Retry the request if the error is due to 401 Unauthorized
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const newToken = await refreshToken();
          // Update the original request with the new token
          originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
          // Retry the original request
          return service(originalRequest);
        } catch (refreshError) {
          console.error('Failed to refresh token:', refreshError);
          throw refreshError;
        }
      }

      // For any other errors, simply throw the error
      return Promise.reject(error);
    }
  );

  return service;
};
