import { FC, JSX, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { LocaleProvider, ReactAppId, useBrand } from '@common/hooks';
import useCountry, { Country } from '@common/hooks/useCountry';
import CountryNotFoundRedirectPage from '@common/pages/CountryNotFoundRedirectPage';
import MonefitEstoniaApp from '@monefit-ee/App';
import MonefitEstoniaErrorBoundary, {
  ErrorBoundaryPageProps as MonefitEstoniaErrorBoundaryProps,
} from '@monefit-ee/pages/error-boundary-page/ErrorBoundaryPage';
import MonefitSpainApp from '@monefit-es/App';

function RootApp(): JSX.Element {
  const { country } = useCountry();
  const { brand } = useBrand();

  const renderApp = useMemo<JSX.Element | null>(() => {
    switch (country) {
      case Country.EE:
        return (
          <LocaleProvider country={Country.EE}>
            {brand === ReactAppId.MONEFIT && <MonefitEstoniaApp />}
          </LocaleProvider>
        );
      case Country.ES:
        return (
          <LocaleProvider country={country}>
            {brand === ReactAppId.MONEFIT && <MonefitSpainApp />}
            {brand === ReactAppId.CREDITSTAR && <div>HOLA!</div>}
          </LocaleProvider>
        );
      default:
        return <CountryNotFoundRedirectPage />;
    }
  }, [country, brand]);

  const renderErrorBoundary = useMemo<FC<MonefitEstoniaErrorBoundaryProps>>(() => {
    switch (country) {
      case Country.EE:
        return MonefitEstoniaErrorBoundary;
      default:
        return () => <div>Unhandled error occurred</div>;
    }
  }, [country]);

  return (
    <BrowserRouter future={{ v7_startTransition: true }}>
      <ToastContainer
        position="top-center"
        theme="colored"
        limit={3}
        autoClose={8000}
        hideProgressBar
        closeButton={false}
        closeOnClick
      />
      <div data-testid="root-app">
        <ErrorBoundary FallbackComponent={renderErrorBoundary}>{renderApp}</ErrorBoundary>
      </div>
    </BrowserRouter>
  );
}

export default RootApp;
