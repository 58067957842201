import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

import { useLocale } from '@common/hooks';
import { getUserDisplayName } from '@common/utils';

const Intercom = () => {
  const { boot, update } = useIntercom();

  const userName = getUserDisplayName();
  const { locale } = useLocale();

  useEffect(() => {
    boot({ languageOverride: locale, name: userName ?? '' });
  }, [locale, boot, userName]);

  useEffect(() => {
    update({ languageOverride: locale, name: userName ?? '' });
  }, [locale, update, userName]);

  return <></>;
};

export default Intercom;
