import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import Button, { ButtonSpacing } from '@common/components/button/Button';
import { emailValidationRegex } from '@common/components/form/helpers';
import TextInput from '@common/components/form/inputs/textInput/TextInput';
import GoogleIcon from '@common/components/icons/GoogleIcon';
import ProtectedIcon from '@common/components/icons/ProtectedIcon';
import SignupBaseCard from '@common/components/sign-up-layout/SignupBaseCard';
import { useLocale } from '@common/hooks';
import { linkBasedRegister, oAuthGetAuthorizationUrl } from '@common/redux/thunks/authentication';
import {
  AuthorizeAction,
  OAuthProvider,
  Region,
  RegisterChannel,
} from '@common/services/authentication';
import { getPublicUrl } from '@common/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '@monefit-es/hooks';
import * as yup from 'yup';

interface FormValues {
  identifier: string;
  displayName: string;
}

const TERMS_AND_PRIVACY_TEXT = (
  <div className="text-[15px] font-medium leading-normal text-black">
    <FormattedMessage
      defaultMessage="By continuing you’re agreeing to the {link} of Monefit."
      description="Monefit-ES By continuing you’re agreeing to the {link} of Monefit."
      values={{
        link: (
          <a
            className="underline"
            rel="noreferrer"
            target="_blank"
            href="https://monefit.com/wp-content/uploads/2024/06/Monefit-Credit-Privacy_Policy_ES-1.pdf"
          >
            <FormattedMessage
              defaultMessage="terms & privacy policy"
              description="Monefit-ES terms & privacy policy"
            />
          </a>
        ),
      }}
    />
  </div>
);

const SignupForm = ({ referrer }: { referrer?: string | null }) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { localeWithCountry } = useLocale();

  const schema: yup.ObjectSchema<FormValues> = useMemo(() => {
    return yup.object({
      displayName: yup
        .string()
        .required(
          formatMessage({
            defaultMessage: 'Name is required',
            description: 'Monefit-ES: Name is required',
          })
        )
        .min(
          3,
          formatMessage({
            defaultMessage: 'Name must be at least 3 characters',
            description: 'Moenfit-ES: Name must be at least 3 characters',
          })
        ),
      identifier: yup
        .string()
        .required(
          formatMessage({
            defaultMessage: 'Email is required',
            description: 'Email:  "Email is required"',
          })
        )
        .matches(
          emailValidationRegex,
          formatMessage({
            defaultMessage: "Invalid email format. Include '@' and a valid domain, like '.com'",
            description: 'Email: "Invalid email format"',
          })
        ),
    });
  }, [formatMessage]);

  const ctx = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const {
    getValues,
    formState: { isSubmitting, isValid },
  } = ctx;

  const handleFormSubmit = useCallback(
    async (v: FormValues) => {
      await dispatch(
        linkBasedRegister({
          ...v,
          referrer,
          region: Region.ES,
          channel: RegisterChannel.EMAIL,
        })
      )
        .unwrap()
        .catch(() => {
          toast(
            formatMessage({
              defaultMessage:
                'Oops! It looks like your login link has expired. Please enter your email address to request a new link. Remember, the link is valid for 15 minutes.',
              description:
                'Monefit-ES: Oops! It looks like your login link has expired. Please enter your email address to request a new link. Remember, the link is valid for 15 minutes.',
            }),
            { type: 'error' }
          );
        });
    },
    [dispatch, referrer, formatMessage]
  );

  const handleGoogleSignup = useCallback(async () => {
    const { REACT_APP_ENVIRONMENT } = process.env;

    const basePublicUrl = getPublicUrl(REACT_APP_ENVIRONMENT);
    const redirectUrl = `${basePublicUrl}/${localeWithCountry}/login?action=${AuthorizeAction.REGISTER}`;
    await dispatch(
      oAuthGetAuthorizationUrl({
        provider: OAuthProvider.GOOGLE,
        redirectUrl: redirectUrl,
        referrer,
        region: Region.ES,
      })
    )
      .unwrap()
      .then((res) => {
        window.location.href = res.url;
      })
      .catch((e) => {
        toast(
          formatMessage({
            defaultMessage:
              'Oops! It looks like your login link has expired. Please enter your email address to request a new link. Remember, the link is valid for 15 minutes.',
            description:
              'Monefit-ES: Oops! It looks like your login link has expired. Please enter your email address to request a new link. Remember, the link is valid for 15 minutes.',
          }),
          { type: 'error' }
        );
      });
  }, [dispatch, localeWithCountry, referrer, formatMessage]);

  return (
    <>
      <SignupBaseCard
        headerExtraClassNames="font-secondary"
        headerText={formatMessage({
          defaultMessage: 'Join the waiting list',
          description: 'Monefit-ES Join the waiting list',
        })}
        subHeaderText={formatMessage({
          defaultMessage: 'Sign up for Monefit and join the waiting list today',
          description: 'Monefit-ES Sign up for Monefit and join the waiting list today',
        })}
      >
        <form
          autoComplete="off"
          className="flex size-full"
          onSubmit={ctx.handleSubmit(async (v: FormValues) => {
            try {
              await handleFormSubmit(v);
            } catch (_) {}
          })}
        >
          <FormProvider {...ctx}>
            <div className="flex size-full flex-col gap-8 sm:gap-12">
              <div className="flex w-full flex-col gap-3 ">
                <TextInput
                  name="displayName"
                  required
                  label={formatMessage({
                    defaultMessage: 'Your first name',
                    description: 'Monefit-ES: Your first name',
                  })}
                />
                <TextInput
                  name="identifier"
                  required
                  label={formatMessage({
                    defaultMessage: 'E-mail',
                    description: 'Monefit-ES: E-mail',
                  })}
                />
              </div>
              {TERMS_AND_PRIVACY_TEXT}
              <div className="flex flex-col gap-2 sm:flex-row sm:gap-2">
                <Button
                  type="submit"
                  onClick={() => handleFormSubmit(getValues())}
                  fullWidth
                  text={formatMessage({ defaultMessage: 'Continue' })}
                  disabled={isSubmitting || !isValid}
                  extraClassNames="font-secondary font-bold text-lg"
                />
                <Button
                  type="button"
                  disabled={isSubmitting}
                  color="gray"
                  fullWidth
                  spacing={ButtonSpacing.UNDEFINED}
                  extraClassNames="px-2 py-4"
                  onClick={handleGoogleSignup}
                  icon={<GoogleIcon />}
                  text={formatMessage({
                    defaultMessage: 'Sign up with Google',
                    description: 'Monefit-ES Sign up with Google',
                  })}
                />
              </div>
            </div>
          </FormProvider>
        </form>
        <div className="inline-flex w-full items-center justify-center gap-2">
          <ProtectedIcon />
          <div className="text-base font-normal leading-normal text-neutral-500">
            <FormattedMessage
              defaultMessage="Your data is protected"
              description="Monefit-ES: Your data is protected"
            />
          </div>
        </div>
      </SignupBaseCard>
    </>
  );
};

export default SignupForm;
