import { ReactNode } from 'react';

import classNames from 'classnames';

interface ImageCardProps {
  children: ReactNode;
  imageSrc?: string;
  imageText?: string;
  hasSmallerImageText?: boolean;
  imageSubText?: string;
  smHeightClassName?: string;
}

const ImageCard = ({
  children,
  imageSrc,
  imageText,
  imageSubText,
  hasSmallerImageText = false,
  smHeightClassName,
}: ImageCardProps) => {
  const textSizeClass = hasSmallerImageText ? 'sm:text-[55px]' : 'sm:text-[65px]';

  return (
    <div
      className={classNames(
        smHeightClassName ?? 'sm:h-[600px]',
        'flex w-full max-w-[1000px] flex-col rounded-3xl lg:h-[600px] sm:flex-row lg:flex-row'
      )}
    >
      <div
        style={{
          ...(imageSrc && {
            backgroundImage: `url(${imageSrc})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }),
        }}
        className="flex h-[270px] w-full min-w-0 flex-col items-center justify-end gap-6 rounded-t-3xl bg-black p-2 sm:h-full sm:rounded-bl-3xl sm:rounded-tr-none sm:p-12"
      >
        {!!imageText && (
          <div
            className={classNames(
              textSizeClass,
              'text-center text-[45px] font-black leading-[55px] text-white'
            )}
            style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}
          >
            {imageText}
            {!!imageSubText && (
              <p
                style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 1)' }}
                className="text-[20px] leading-normal"
              >
                {imageSubText}
              </p>
            )}
          </div>
        )}
      </div>
      <div className="flex size-full min-w-0 flex-col  items-center justify-between rounded-b-3xl bg-white p-5 sm:rounded-bl-none sm:rounded-tr-3xl sm:p-12">
        {children}
      </div>
    </div>
  );
};
export default ImageCard;
