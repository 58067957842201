import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Button, { ButtonSpacing } from '@common/components/button/Button';
import TextInput from '@common/components/form/inputs/textInput/TextInput';
import SignupBaseCard from '@common/components/sign-up-layout/SignupBaseCard';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import AddressInput from './inputs/AddressInput';

interface FormValues {
  address: {
    region: string;
    locality: string;
    street: string;
    postcode: string;
    houseNo: string;
    houseName?: string | null;
  };
  floorNumber: number;
  door: number;
}

const AddressForm = () => {
  const { formatMessage } = useIntl();
  const [isManualAddressEnter, setIsManualAddressEnter] = useState(false);

  const schema: yup.ObjectSchema<FormValues> = useMemo(() => {
    return yup.object({
      address: yup.object({
        region: yup.string().required(formatMessage({ defaultMessage: 'Field is required' })),
        locality: yup.string().required(formatMessage({ defaultMessage: 'Field is required' })),
        street: yup.string().required(formatMessage({ defaultMessage: 'Field is required' })),
        postcode: yup.string().required(formatMessage({ defaultMessage: 'Field is required' })),
        houseNo: yup.string().required(formatMessage({ defaultMessage: 'Field is required' })),
        houseName: yup.string().optional().nullable(),
      }),

      floorNumber: yup.number().required(),
      door: yup.number().required(),
    });
  }, [formatMessage]);

  const ctx = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const {
    setValue,
    formState: { isSubmitting, isValid },
  } = ctx;

  const handleSwitchToManualEnter = useCallback(() => {
    setIsManualAddressEnter(true);
    setValue('address.houseName', '');
    setValue('address.postcode', '');
    setValue('address.street', '');
    setValue('address.houseNo', '');
    setValue('address.locality', '');
    setValue('address.region', '');
  }, [setValue]);

  const handleSubmit = useCallback(async (values: FormValues) => {
    console.log('values', values);
  }, []);

  return (
    <SignupBaseCard
      headerText={formatMessage({
        defaultMessage: 'Where are you currently living?',
        description: 'Where are you currently living?',
      })}
      subHeaderText={formatMessage({
        defaultMessage:
          'Please begin by typing your street address and number to locate your address.',
        description:
          'Please begin by typing your street address and number to locate your address.',
      })}
    >
      <form
        autoComplete="off"
        className="flex size-full"
        onSubmit={ctx.handleSubmit(async (v: FormValues) => {
          try {
            await handleSubmit(v);
          } catch (_) {}
        })}
      >
        <FormProvider {...ctx}>
          <div className="flex size-full flex-col gap-3 sm:gap-3">
            {isManualAddressEnter ? (
              <>
                <TextInput
                  name="address.street"
                  label={formatMessage({ defaultMessage: 'Street name' })}
                />
                <TextInput
                  name="address.houseName"
                  label={formatMessage({ defaultMessage: 'House name' })}
                />
                <TextInput
                  name="address.houseNo"
                  label={formatMessage({ defaultMessage: 'House number' })}
                />
              </>
            ) : (
              <div>
                <AddressInput name="address" label={formatMessage({ defaultMessage: 'Address' })} />
                <Button
                  extraClassNames="mt-1 mb-1"
                  onClick={handleSwitchToManualEnter}
                  spacing={ButtonSpacing.NONE}
                  color="underlined-black"
                  text={formatMessage({ defaultMessage: "I can't find my address" })}
                />
              </div>
            )}
            <TextInput
              name="floorNumber"
              step="1"
              type="number"
              label={formatMessage({ defaultMessage: 'Floor number' })}
            />
            <TextInput
              name="door"
              step="1"
              type="number"
              label={formatMessage({ defaultMessage: 'Door' })}
            />
            {isManualAddressEnter && (
              <>
                <TextInput
                  type="number"
                  name="address.postcode"
                  label={formatMessage({ defaultMessage: 'Postal code' })}
                />
                <TextInput
                  name="address.locality"
                  label={formatMessage({ defaultMessage: 'Town / Municipality' })}
                />
                <TextInput
                  name="address.region"
                  label={formatMessage({ defaultMessage: 'Region' })}
                />
              </>
            )}
            <Button
              extraClassNames="mt-5"
              type="submit"
              disabled={isSubmitting || !isValid}
              text={formatMessage({ defaultMessage: 'Continue' })}
            />
          </div>
        </FormProvider>
      </form>
    </SignupBaseCard>
  );
};

export default AddressForm;
